.sidebar-col {
    -ms-flex: 0 0 314px;
    flex: 0 0 314px;
    z-index: 10;
}
.Sidebar {
    position: absolute;
    top: 0;
    left: 0;
    width: 314px;
    height: 100%;
}

.sticky-sidebar {
    position: fixed;
    top: 0;
    left: 0;
}
.sidebar-header {
    height: 60px;
}
.navbar-header {
    height: 42px;
}
.header-logo {
    cursor: pointer;
}
.header-text {
    font-size: 1.125rem;
    font-weight: 700;
    font-family: "Quicksand", sans-serif;
    color: var(--primary);
    margin-bottom: 0;
    cursor: pointer;
}
.sidebar-div {
    height: 70px;
    padding: 0px 24px;
}
.sidebar-user {
    background-color: var(--bg-block-inverse);
}
.user-logo {
    height: 48px;
    width: 48px;
}
.sidebar-username {
    font-size: 1rem;
    font-family: "Open Sans", sans-serif;
    margin-bottom: 0.4rem;
    font-weight: 600;
}
.sidebar-email {
    font-size: 0.75rem;
    font-weight: 400;
    font-family: "Open Sans", sans-serif;
}
.cursor-pointer {
    cursor: pointer;
}
.sidebar-icon {
    font-family: "devasIcon";
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    display: inline-block;
    text-decoration: inherit;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 24px;
    font-size: 20px;
    width: 24px !important;
    height: 24px !important;
}
.sidebar-div:hover .sidebar-icon,
.sidebar-div:hover .sidebar-menu-text {
    color: var(--primary);
}

.sidebar-icon.icon-offers {
    font-size: 15px;
}

.sidebar-menu-text {
    font-weight: 600;
}

.is-selected .sidebar-icon,
.is-selected .sidebar-menu-text {
    color: var(--primary);
}
.sidebar-user.dropright .dropdown-toggle {
    background: transparent;
    border: none;
}
.dropright .dropdown-toggle::after {
    color: var(--text);
}
.sidebar-user.dropright .dropdown-toggle:focus,
.sidebar-user.dropright .dropdown-toggle:focus:active {
    outline: none;
    box-shadow: none;
}
.sidebar-user.dropright .dropdown-toggle:active {
    background: transparent;
}
.dropright .dropdown-item {
    font-size: 0.9rem;
    cursor: pointer;
    background-color: var(--bg-menu);
    color: var(--text);
    font-weight: 600;
}
.dropright .dropdown-item:hover {
    background: var(--secondary);
    color: var(--bg-menu);
}
.dropright .dropdown-item:active {
    background: var(--secondary);
    color: var(--bg-menu);
}

.sidebar-small .header-text,
.sidebar-small .sidebar-menu-text,
.sidebar-small .sidebar-username,
.sidebar-small .sidebar-email, 
.sidebar-small .sidebar-user {
    display: none;
}
.sidebar-small .user-logo {
    height: 28px;
    width: 28px;
    cursor: pointer;
}
.sidebar-small.sidebar-col{
    -ms-flex: 0 0 72px;
    flex: 0 0 72px;
}
.sidebar-small .Sidebar{
    width: 72px;
}
.sidebar-small .header-logo{
    width: 44px;
}
.min-width-0{
    min-width: 0;
}
.version-text {
    font-size: 0.7rem; 
    margin-left: 0.2rem;
}