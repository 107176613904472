.Dashboard .row h5 {
    font-size: 0.8rem;
}
.Dashboard .row h4 {
    font-size: 1.1rem;
    font-weight: 700;
}
.block-trial {
    position: relative;
    height: 18px;
    width: 80px;
    margin-top: -13px;
    background-color: var(--bg-menu);
    overflow: hidden;
}
.offer-link {
    font-size: 0.8rem;
    color: var(--text);
    font-weight: 600;
}
.offer-link:hover {
    text-decoration: none;
    color: var(--secondary);
}
.chartContainer {
    height: 230px;
    width: 100%;
}
@media (min-width: 768px) {
    .chartContainer {
        height: 300px;
    }
}
@media (min-width: 1200px) {
    .chartContainer {
        height: 400px;
    }
}
