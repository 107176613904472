.img-responsive {
    max-width: 100%;
    height: 200px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top;
    background-color: var(--bg-menu);
}
.priceText {
    font-size: 1.1rem;
    color: rgb(210, 210, 210);
    margin-right: 0.4rem;
}
.price {
    font-size: 1.7rem;
    color: rgb(255, 10, 20);
    margin-right: 0.4rem;
}
.text {
    font-size: 0.85rem;
}
.bold-text {
    font-weight: 700;
}
.w-200 {
    width: 200px;
}
.color-primary {
    color: var(--primary);
}
.border-top-link {
    border-top: 3px solid var(--bg-menu);
}
.border-bottom-thin-link {
    border-bottom: 2px solid var(--bg-block-inverse);
}
.border-primary-link {
    border-color: var(--primary);
}
.medium-text {
    font-size: 0.9rem;
}
.small-text-offer-details{
    font-size: 0.8rem;
}
.fade-out-transition {
    -webkit-transition: opacity 0.3s ease-in-out;
    -moz-transition: opacity 0.3s ease-in-out;
    -ms-transition: opacity 0.3s ease-in-out;
    -o-transition: opacity 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out;
}
.opacity-0 {
    opacity: 0;
}
.opacity-1 {
    opacity: 1;
}
.color-red {
    color: var(--red);
}
@media (min-width: 576px) {
    .img-responsive {
        max-width: 100%;
        width: auto;
        height: 250px;
    }
}

@media (min-width: 768px) {
    .img-responsive {
        max-width: 100%;
        width: auto;
        height: 260px;
    }
    .offer-admin .img-responsive{
        height: 340px;
    }
}

@media (min-width: 992px) {
    .img-responsive {
        max-width: 100%;
        width: auto;
        height: 275px;
    }
    .offer-admin .img-responsive{
        height: 400px;
    }
}

@media (min-width: 1200px) {
    .offer-admin .img-responsive{
        height: 400px;
    }
}
