.HomePage {
    background: var(--bg-auth);
    min-height: 100vh;
    height: 100%;
}
.HomePage .container {
    background-color: var(--bg-auth);
    width: 90%;
    max-width: 900px;
    padding: 45px 0px;
    color: var(--text);
}
.HomePage .logo {
    object-fit: cover;
    width: 44px;
    height: 44px;
}
.HomePage .text-header {
    margin: 0px;
    color: var(--primary);
    font-family: "Quicksand", sans-serif;
    font-weight: 700;
    font-size: 30px;
}
.item-width {
    width: 90%;
    margin: auto;
    max-width: 300px;
}
.login-form {
    padding-top: 45px;
}
.HomePage .form-group label {
    font-weight: 600;
}
.btn-submit {
    background-color: var(--primary);
    width: 100%;
    border-radius: 1.25rem;
    height: 2.5rem;
    color: white;
    font-weight: 600;
    margin-top: 0.35rem;
    font-family: "Open Sans", sans-serif;
}
.btn-submit.disabled {
    background-color: var(--button-disabled);
}
.btn-submit:hover {
    color: white;
}
.link-tag {
    color: var(--text);
    font-weight: 600;
    font-family: "Open Sans", sans-serif;
}
.link-tag:hover {
    color: var(--text);
    text-decoration: none;
}
.link-tag.link-primary {
    color: var(--primary);
}
.link-tag.link-primary:hover {
    color: var(--primary);
}
.HomePage .login-home-page, .HomePage .login-page {
    height: 100vh;
}
.HomePage .logo-title{
    cursor: pointer;
}
@media (min-width: 576px) {
    .HomePage .login-home-page {
        height: 70%;
        max-height: 450px;
    }
    .HomePage .login-page{
        height: auto;
    }
    .HomePage {
        background: url(../img/loginbackground.jpg) 50% / cover no-repeat;
        height: 100vh;
    }
    .item-width {
        width: 60%;
        max-width: 400px;
    }
    .HomePage .container.login-home-page{
        width: 80%;
        max-width: 700px
    }
}

