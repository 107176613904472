.guide-title {
    font-size: 1.125rem;
    font-weight: 600;
}
.guide-subtitle {
    font-size: 0.85rem;
    font-weight: 300;
}
.mt-2-5 {
    margin-top: 0.8rem;
}
.guide-section-title {
    font-size: 1rem;
    font-weight: 600;
}
.guide-body div {
    font-size: 0.85rem;
    font-weight: 300;
}
.guide-body label {
    font-size: 0.85rem;
    font-weight: 600;
}
