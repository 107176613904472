.bg-datepicker {
    background-color: var(--bg-menu);
    box-shadow: var(--box-shadow);
    color: var(--text);
    border-color: var(--border-defaul);
    height: 38px;
}
.react-date-picker__inputGroup{
    text-align: center;
}
.date-form .bg-datepicker {
    box-shadow: none;
    border: 1px solid var(--border-default);
}
.react-date-picker {
    width: 100%;
    height: 100%;
}
.react-date-picker__wrapper {
    border: none;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    padding: 0px 8px 0px 16px;
}
.react-date-picker__button:focus {
    outline: none;
}
.react-daterange-picker__button .react-daterange-picker__button__icon {
    stroke: var(--text);
}
.react-date-picker__button:enabled:hover .react-date-picker__button__icon,
.react-date-picker__button:enabled:focus .react-date-picker__button__icon {
    stroke: var(--secondary);
}
.react-date-picker__inputGroup {
    justify-content: center;
    font-size: 0.8rem;
}
.react-date-picker__range-divider {
    font-size: 0.45rem;
    display: flex;
    align-items: center;
}
.react-calendar__tile--active {
    background: var(--secondary);
}
.react-calendar__tile--hasActive {
    background: var(--secondary-light);
}
.react-calendar__tile--hasActive:hover {
    background: var(--secondary-light);
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: var(--secondary-light);
}
.react-date-picker__inputGroup__input {
    color: var(--text);
}
@media (min-width: 576px) {
    .react-date-picker__inputGroup {
        font-size: 0.5rem;
    }
    .react-date-picker__range-divider {
        font-size: 0.5rem;
    }
}
@media (min-width: 1200px) {
    .react-date-picker__inputGroup {
        font-size: 0.6rem;
    }
    .react-date-picker__range-divider {
        font-size: 0.6rem;
    }
}
@media (min-width: 1350px) {
    .react-date-picker__inputGroup {
        font-size: 0.8rem;
    }
    .react-date-picker__range-divider {
        font-size: 0.8rem;
    }
}
.react-calendar__navigation button {
    color: var(--text);
}
.react-calendar--selectRange .react-calendar__tile--hover {
    color: var(--gray);
}
.react-calendar__month-view__days__day--weekend {
    color: var(--red) !important;
}
.react-calendar button {
    color: var(--text);
}
.react-calendar {
    background-color: var(--bg-menu);
    border-color: var(--bg-menu);
}
.border-grey{
    border: 1px solid var(--border-default);
}
