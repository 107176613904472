.drawer-guide {
    background-color: var(--bg-menu);
    color: var(--text);
}
.drawer-width {
    max-width: 750px;
    width: 90vw;
}
.drawer-height {
    min-height: 100%;
}
.CircularProgressbar {
    width: 45px;
}
.CircularProgressbar .CircularProgressbar-path {
    stroke: var(--bg-menu);
}
.CircularProgressbar .CircularProgressbar-trail {
    stroke: var(--gray);
}
.CircularProgressbar .CircularProgressbar-text {
    fill: var(--bg-menu);
}
.CircularProgressbar .CircularProgressbar-background {
    fill: var(--primary);
}
.guide-top-menu {
    background: linear-gradient(to left, #ee5924 0, #f5a623 100%);
    min-width: 110px;
    min-height: 110px;
    color: var(--bg-menu);
}
.guide-top-title {
    margin-left: 18px;
}
.guide-title {
    font-size: 1rem;
    margin: 0;
    padding: 0;
    margin-bottom: 3px;
    font-weight: 700;
}
.guide-subtitle {
    font-size: 0.85rem;
    margin: 0;
    padding: 0;
}
.guide-top .guide-menu-list::before {
    content: "";
    display: block;
    position: absolute;
    top: 110px;
    left: 46px;
    z-index: 0;
    width: 1px;
    height: 50px;
    background: #ebeff3;
}
.border-bottom-menu {
    border-bottom: 1px solid #ebeff3;
}
.border-top-menu {
    border-top: 1px solid #ebeff3;
}
.guide-menu-list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.guide-list-item {
    margin: 10px 0;
    padding: 8px 20px;
    border-radius: 20px;
    border: 1px solid transparent;
    z-index: 1;
    transition: 0.3 ease;
    position: relative;
    cursor: pointer;
}
.guide-list-item:hover,
.guide-list-item-active {
    border: 1px solid #ee5924;
    background-color: rgba(238, 89, 36, 0.17);
}
.list-item-circle {
    border-radius: 50%;
    border: 1px solid #ebeff3;
    width: 21px;
    height: 21px;
    display: inline-block;
    background: #fff;
    margin-right: 10px;
    z-index: 1;
    position: absolute;
    top: 8px;
    text-align: center;
    line-height: 1rem;
    color: var(--primary);
}
.list-item-label {
    display: block;
    padding-left: 30px;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    box-sizing: border-box;
    font-size: 0.85rem;
    margin-bottom: 0px;
    padding-top: 1px;
}
.p-15 {
    padding: 15px;
}
.guide-collapsible {
    padding-left: 30px;
    cursor: pointer;
}
.collapsible-open svg {
    transform: rotate(90deg);
}
.guide-collapsible-text {
    padding-left: 10px;
    font-size: 0.875rem;
    font-weight: 700;
    color: var(--text);
}
.right-menu-guide {
    height: 100vh;
    overflow-y: auto;
}
.left-menu-guide {
    max-height: 100vh;
    overflow-y: auto;
}
