.navbar-mobile .MuiSvgIcon-root {
    fill: var(--nav-btn);
}
.navbar-mobile .MuiBottomNavigationAction-root.Mui-selected .MuiSvgIcon-root {
    fill: var(--primary);
}
.navbar-mobile .MuiBottomNavigationAction-root {
    color: var(--nav-btn);
}
.navbar-mobile .MuiBottomNavigationAction-root.Mui-selected {
    color: var(--primary)
}
.MuiPaper-rounded {
    border-radius: 0px !important;
}
.navbar-mobile .MuiBottomNavigation-root{
    min-height: 60px;
}
.navbar-mobile .MuiBottomNavigationAction-label{
    font-size: 0.65rem;
}
.navbar-mobile .MuiBottomNavigationAction-label.Mui-selected{
    font-size: 0.75rem;
    color: var(--primary)
}
.navbar-mobile .sidebar-icon, .menu-icon{
    font-size: 0.8rem;
}
.navbar-mobile .MuiBottomNavigationAction-root{
    max-width: 80px;
    min-width: 45px;
}
.navbar-mobile .sidebar-icon.icon-offers {
    font-size: 0.75rem;
}
.navbar-mobile .sidebar-icon.icon-toggle {
    font-size: 0.85rem;
}
.navbar-mobile .MuiToolbar-regular {
    min-height: 64px;
}
@media (min-width: 600px) {
    .navbar-mobile .MuiToolbar-regular {
        min-height: 64px;
    }
}
.version-text {
    font-size: 0.7rem; 
    margin-left: 0.2rem;
}