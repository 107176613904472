.search-input {
    padding-left: 16px;
    font-size: 0.8rem;
    height: 38px;
}
.img-offerlist {
    width:100%;
    height: auto;
}
.offerlist-link {
    font-size: 0.8rem;
    color: var(--text);
}
.offerlist-link:hover {
    text-decoration: none;
    color: var(--primary);
}